import { TableSummary } from "@/components/Shared/tables/TableSummary/TableSummary";
import { Invoice } from "@/lib/features/invoices/model/invoice.model";
import { useGetInvoiceReadModelsQuery } from "@/lib/sdk/sdk";
import { proTableSharedConfig } from "@/lib/shared/config/pro-table-config";
import { useProTableControls } from "@/lib/shared/hooks/table/useProTableControls/useProTableConfig";
import { useStrapiFilterQueryVars } from "@/lib/shared/hooks/table/useStrapiFilterQueryVars";
import { useControlledColumnConfig } from "@/lib/shared/hooks/useColumnConfig";
import {
  generatePlaceholderData,
  makeLoadingTableColumns,
} from "@/lib/utils/table/loading-table";
import { onlyTruthy } from "@/lib/utils/values";
import { ProFormInstance } from "@ant-design/pro-form";
import ProTable, { ProTableProps } from "@ant-design/pro-table";
import React, { memo, useRef } from "react";
import { useInvoiceTableColumns } from "./hooks/useInvoiceTableColumns";
import { transformInvoiceSearchFormDataToFilters } from "./utils/transormSearchFormDataToFilters";

export interface InvoiceTableProps extends ProTableProps<Invoice, any> {
  className?: string;
  predefinedFilters?: any;
}

export const InvoiceTable = memo(function InvoiceTable(
  props: InvoiceTableProps
) {
  const ref = useRef<ProFormInstance>();
  const columns = useInvoiceTableColumns();

  const {
    defaultProps,
    pagination,
    filters,
    setFilters,
    sorter,
    setSorter,
    onTableChange,
  } = useProTableControls({
    searchFormRef: ref,
    tableColumns: columns,
    tableName: "invoices",
    defaultFilters: props.predefinedFilters,
  });

  const { columnConfig, setColumnConfig } = useControlledColumnConfig(
    columns,
    "invoices"
  );

  const queryVars = useStrapiFilterQueryVars({
    pagination,
    filters,
    sorter,
    filtersTransformer: transformInvoiceSearchFormDataToFilters,
  });

  const { data, isLoading, isFetching, refetch } = useGetInvoiceReadModelsQuery(
    queryVars,
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <ProTable<any>
        {...defaultProps}
        formRef={ref}
        scroll={{ x: "max-content" }}
        rowKey={(record) => record.id}
        loading={isLoading || isFetching}
        columns={isLoading ? makeLoadingTableColumns(columns) : columns}
        pagination={{
          ...defaultProps.pagination,
          ...(pagination as any),
          total: data?.invoiceReadModelCount,
        }}
        dataSource={
          data?.invoiceReadModels.filter(onlyTruthy) ||
          generatePlaceholderData(20)
        }
        onChange={onTableChange}
        onReset={() => {
          setFilters({});
        }}
        options={{
          ...proTableSharedConfig.options,
          reload: () => refetch(),
        }}
        columnsStateMap={columnConfig}
        onColumnsStateChange={(config) => setColumnConfig(config)}
        onSubmit={(searchFormData) => {
          setFilters(searchFormData);
        }}
        summary={(pageData) => {
          return (
            <TableSummary
              tableData={pageData}
              columnConfig={columnConfig}
              sumColumns={["sum"]}
            />
          );
        }}
        {...props}
      />
    </>
  );
});
