import { graphqlApi } from "@/lib/api/graphqlApi";
import { GET_SINGLE_INVOICE_QUERY, GET_INVOICES_QUERY } from "../graphql/invoice.queries";
import { UpdateInvoiceMutation, CreateInvoiceMutation, DeleteInvoiceMutation } from "../graphql/invoice.mutations";
import { CollectionQueryInput } from "@/lib/features/shared/model/collection-query-input.model";
import { Invoice } from "../model/invoice.model";
import { GetSingleInvoiceReturn, GetInvoiceCollectionReturn } from "../model/actions/invoice-read.model";
import { UpdateInvoiceReturn, UpdateInvoiceInput } from "../model/actions/invoice-update.model";
import { CreateInvoiceInput, CreateInvoiceReturn } from "../model/actions/invoice-create.model";
import { DeleteInvoiceInput, DeleteInvoiceReturn } from "../model/actions/invoice-delete.model";


/** Api method to create a invoice */
export const createInvoice = async (payload: CreateInvoiceInput): Promise<Invoice> => {
  const { data: { createInvoice: { invoice } } } = await graphqlApi<CreateInvoiceReturn>(CreateInvoiceMutation, {
    variables: {
      input: payload
    }
  })

  return invoice
};

/** Api method to fetch a single invoice */
export const getSingleInvoice = async (id: number): Promise<Invoice> => {
  const { data: { invoice } } = await graphqlApi<GetSingleInvoiceReturn>(GET_SINGLE_INVOICE_QUERY, {
    variables: {
      id
    }
  })

  return invoice
};

/** Api method to fetch multiple invoices in a collection with all the filters specified in queryInput applied */
export const getInvoiceCollection = async (queryInput: CollectionQueryInput) => {
  const { data } = await graphqlApi<GetInvoiceCollectionReturn>(GET_INVOICES_QUERY, {
    variables: queryInput
  })
  return data;
};

/** Api method to update invoice */
export const updateInvoice = async (updatePayload: UpdateInvoiceInput): Promise<Invoice> => {
  const { data: {
    updateInvoice: { invoice }
  } } = await graphqlApi<UpdateInvoiceReturn>(UpdateInvoiceMutation, {
    variables: {
      input: updatePayload
    }
  })

  return invoice
};

/** Api method to delete invoice */
export const deleteInvoice = async (deleteInput: DeleteInvoiceInput): Promise<Invoice> => {
  const { data: {
    deleteInvoice: { invoice }
  } } = await graphqlApi<DeleteInvoiceReturn>(DeleteInvoiceMutation, {
    variables: {
      input: deleteInput
    }
  })

  return invoice
};
