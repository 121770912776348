import { ColumnsState, ProColumnType } from "@ant-design/pro-table";
import { useCallback, useState } from "react";
import { CustomColumnConfig } from "../model/column-state";

const getColumnConfigStorageKey = (tableName: string) =>
  `${tableName}-column-config`;

/**
 * Custom hook for controlled table config
 *
 * Reason: in the Custom Table Summary component we need the key which
 * by default is not provided so we need to add it to the config ourselves
 */
export const useControlledColumnConfig = (
  columns: ProColumnType<any>[],
  tableName: string
) => {
  const [columnConfig, setColumnConfigInternal] = useState<CustomColumnConfig>(
    () => {
      // If we have a column config in local storage, use it
      if (localStorage.getItem(getColumnConfigStorageKey(tableName))) {
        return JSON.parse(
          localStorage.getItem(getColumnConfigStorageKey(tableName))
        );
      }

      const initialConfig: CustomColumnConfig = {};
      let order = 1;

      for (const column of columns) {
        initialConfig[column?.key] = {
          key: `${column?.key}`,
          show: column.hideInDescriptions ? false : true,
          order,
        };

        if (column.fixed && typeof column.fixed === "string") {
          initialConfig[column?.key].fixed = column?.fixed;
        }

        order += 1;
      }

      return initialConfig;
    }
  );

  const setColumnConfig = useCallback(
    (originalConfig: Record<string, ColumnsState>) => {
      setColumnConfigInternal(() => {
        const newColumnConfig: CustomColumnConfig = {};

        for (const columnKey of Object.keys(originalConfig)) {
          newColumnConfig[columnKey] = {
            ...originalConfig[columnKey],
            key: columnKey,
          };
        }

        localStorage.setItem(
          getColumnConfigStorageKey(tableName),
          JSON.stringify(newColumnConfig)
        );

        return newColumnConfig;
      });
    },
    []
  );

  return { columnConfig, setColumnConfig };
};
