import { gql } from "graphql-tag";
import { invoiceFragment } from "./invoice.fragment";

// Mutation to create invoice
export const CreateInvoiceMutation = gql`
	${invoiceFragment}
	mutation createInvoiceMutation($input: createInvoiceInput) {
		createInvoice(input: $input) {
			invoice {
				...invoiceFragment
			}
		}
	}
`;

// Mutation to update invoice
export const UpdateInvoiceMutation = gql`
	${invoiceFragment}
	mutation updateInvoiceMutation($input: updateInvoiceInput) {
		updateInvoice(input: $input) {
			invoice {
				...invoiceFragment
			}
		}
	}
`;

// Mutation to delete invoice
export const DeleteInvoiceMutation = gql`
	${invoiceFragment}
	mutation deleteInvoiceMutation($input: deleteInvoiceInput) {
		deleteInvoice(input: $input) {
			invoice {
				...invoiceFragment
			}
		}
	}
`;
