import { gql } from "graphql-tag";

export const invoiceFragment = gql`
  fragment invoiceFragment on Invoice {
    id
    dateReceived
    invoiceNumber
    subject
    variableSymbol
    pairingSymbol
    status
    type
    approvalProcess {
      status
      waitingOn {
        name
      }
    }
    attachments {
      id
      name
      type
    }
    department {
      id
      name
    }
    partner {
      id
      name
    }
    dueDate
    origin {
      id
    }
    project {
      name
      id
    }
    sum
  }
`;
