import { asRoute } from "@/lib/routes/route-utils";

export const invoiceRoutes = {
  add: asRoute({
    href: "/invoices/new",
    label: "common:menu.invoice.add",
  }),
  list: asRoute({
    href: "/invoices",
    label: "common:menu.invoice.list",
  }),
  edit: asRoute({
    href: "/invoices/edit",
    label: "common:menu.invoice.edit",
  }),
};
export const generateInvoiceRoute = (id: number) => `/invoices/edit?id=${id}`;
