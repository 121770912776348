import moment, { Moment } from "moment";

export const andDatePickerPresetRanges: Record<string, [Moment, Moment]> = {
  Dnes: [moment(), moment()],
  "Tento týždeň": [moment().startOf("week"), moment().endOf("week")],
  "Budúci týždeň": [
    moment().add(1, "week").startOf("week"),
    moment().add(1, "week").endOf("week"),
  ],
  "Tento mesiac": [moment().startOf("month"), moment().endOf("month")],
  "Budúci mesiac": [
    moment().add(1, "month").startOf("month"),
    moment().add(1, "month").endOf("month"),
  ],
};
