import {
  TableFilter,
  TableFilterTypes,
} from "@/lib/features/shared/model/table-filter-types";
import { InvoiceReadModelFragmentFragment } from "@/lib/sdk/sdk";

export const transformInvoiceSearchFormDataToFilters = (
  searchFormValues: Partial<InvoiceReadModelFragmentFragment> = {}
): Record<string, TableFilter> => {
  return {
    invoiceNumber: {
      type: TableFilterTypes.Search,
      values: {
        query: searchFormValues?.invoiceNumber,
      },
    },
    approvalProcessWaitingOn: {
      type: TableFilterTypes.SingleRelationship,
      values: {
        relation: searchFormValues?.approvalProcessWaitingOn,
      },
    },
    approvalProcessStatus: {
      type: TableFilterTypes.Raw,
      values: {
        value: {
          approvalProcessStatus: searchFormValues?.approvalProcessStatus,
        },
      },
    },
    pairingSymbol: {
      type: TableFilterTypes.Search,
      values: {
        query: searchFormValues?.pairingSymbol,
      },
    },
    dateReceived: {
      type: TableFilterTypes.DateRange,
      values: {
        range: searchFormValues?.dateReceived,
      },
    },
    dueDate: {
      type: TableFilterTypes.DateRange,
      values: {
        range: searchFormValues?.dueDate,
      },
    },
    project: {
      type: TableFilterTypes.SingleRelationship,
      values: {
        relation: searchFormValues?.project,
      },
    },
    partner: {
      type: TableFilterTypes.SingleRelationship,
      values: {
        relation: searchFormValues?.partner,
      },
    },
    department: {
      type: TableFilterTypes.SingleRelationship,
      values: {
        relation: searchFormValues?.department,
      },
    },
    subject: {
      type: TableFilterTypes.Search,
      values: {
        query: searchFormValues?.subject,
      },
    },
  };
};
