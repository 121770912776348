import { queryClient } from "@/lib/react-query/client";
import { convertIdToNumber } from "@/lib/utils/convert-it";
import { useQuery, UseQueryOptions } from "react-query";
import { getSingleInvoice } from "../api/invoice.api";
import { USE_SINGLE_INVOICE_QUERY_KEY } from "../consts/invoice.query-keys";
import { Invoice } from "../model/invoice.model";

export const prefetchSingleInvoiceQuery = async (id: number | string) => {
  await queryClient.prefetchQuery(
    [USE_SINGLE_INVOICE_QUERY_KEY, { id: convertIdToNumber(id) }],
    () => getSingleInvoice(convertIdToNumber(id))
  );
};
export const useSingleInvoiceQuery = (
  id: number | string,
  options: UseQueryOptions<Invoice>
) => {
  return useQuery<Invoice>(
    [USE_SINGLE_INVOICE_QUERY_KEY, { id: convertIdToNumber(id) }],
    () => getSingleInvoice(convertIdToNumber(id)),
    {
      staleTime: 8000,
      ...options,
    }
  );
};
