import { getAttachmentTypeIcon } from "@/components/Shared/form/fields/AttachementsField/AttachmentIconRender";
import { CompanyPartnerSelect } from "@/lib/features/company-partners/components/CompanyPartnerSelect/CompanyPartnerSelect";
import { SingleDepartmentPicker } from "@/lib/features/departments/components/SingleDepartmentPicker/SingleDepartmentPicker";
import { InvoiceApprovalProcessStatus } from "@/lib/features/invoices/model/invoice.model";
import { SingleProjectSelect } from "@/lib/features/projects/components/form/selects/SingleProjectSelect/SingleProjectSelect";
import { useGetAttachment } from "@/lib/features/s3-files/hooks/useGetAttachment";
import { PdfViewer } from "@/lib/features/shared/components/PdfViewer/PdfViewer";
import { SingleUserSelect } from "@/lib/features/users/components/form/selects/SingleUserSelect/SingleUserSelectDynamic";
import {
	Enum_Invoicereadmodel_Approvalprocesscurrentsteptype,
	InvoiceReadModel,
} from "@/lib/sdk/sdk";
import { andDatePickerPresetRanges } from "@/lib/shared/consts/ant-datepicker-ranges";
import { FileDoneOutlined } from "@ant-design/icons";
import { ProFormDateRangePicker, ProFormSelect } from "@ant-design/pro-form";
import { ProColumns } from "@ant-design/pro-table";
import { Box } from "@chakra-ui/react";
import { Tooltip } from "antd";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useMemo } from "react";
import HoverComponent from "react-hover-action-delay";
import { generateInvoiceRoute } from "../../../routes/invoice.routes";
import { prefetchInvoice } from "../../../utils/prefetchInvoice";
import { QuickApprovalActions } from "../components/QuickApprovalActions";
import { approvalStatusIconMap } from "../utils/approval-status-icon-map";

const InvoiceIdColumn = ({ invoice }) => {
	return (
		<HoverComponent timer={300} action={() => prefetchInvoice(invoice)}>
			<Link href={generateInvoiceRoute(invoice.source_entity?.id)}>
				<Box as="a" fontWeight="semibold">
					{invoice.invoiceNumber}
				</Box>
			</Link>
		</HoverComponent>
	);
};

const AttachmentItem = ({ attachment, ...rest }) => {
	const [visible, setVisible] = React.useState(false);
	const { data } = useGetAttachment(attachment);

	const dataUrl = useMemo(() => {
		if (!data) return;
		return URL.createObjectURL(data);
	}, [data]);

	return (
		<div className="cursor-pointer items-center" {...rest}>
			<PdfViewer
				pdf={dataUrl}
				visible={visible}
				onCancel={() => {
					setVisible(false);
				}}
			/>
			<Box
				cursor={attachment.type === "application/pdf" ? "pointer" : "default"}
				onClick={() => {
					if (attachment.type === "application/pdf") {
						setVisible(true);
					}
				}}
				className="cursor-pointer items-center flex"
			>
				{getAttachmentTypeIcon(attachment.type)}
			</Box>
		</div>
	);
};

export const useInvoiceTableColumns = () => {
	const { t } = useTranslation("invoice");

	const columns: ProColumns<InvoiceReadModel>[] = [
		{
			title: <FileDoneOutlined />,
			formItemProps: {
				label: "Stav schvalovania",
			},
			width: 40,
			dataIndex: "approvalProcessStatus",
			key: "approvalProcessStatus",
			render: (value: InvoiceApprovalProcessStatus) => {
				return approvalStatusIconMap[value];
			},
			renderFormItem: (_) => {
				return (
					<ProFormSelect
						noStyle
						name="approvalProcessStatus"
						options={[
							{ label: "Nezačaný", value: "notStarted" },
							{ label: "V priebehu", value: "inProgress" },
							{ label: "Riešenie zamietnutia", value: "disputeResolution" },
							{ label: "Ukončený", value: "finished" },
							{ label: "Ukončený zamietnutý", value: "finishedRejected" },
						]}
						fieldProps={{
							optionItemRender(item) {
								return (
									<Box d="flex" alignItems="center">
										<div className="icon">
											{approvalStatusIconMap[item.value]}
										</div>
										<Box ml="2" className="label">
											{item.label}
										</Box>
									</Box>
								);
							},
						}}
					/>
				);
			},
		},
		{
			title: t("attributes.invoiceNumber.label"),
			dataIndex: "invoiceNumber",
			key: "invoiceNumber",
			width: 200,
			render: (value: any, invoice: any) => {
				return <InvoiceIdColumn invoice={invoice} />;
			},
			sorter: true,
		},
		{
			title: "Párovací symbol",
			dataIndex: "pairingSymbol",
			key: "pairingSymbol",
			valueType: "text",
			width: 130,
			sorter: true,
		},
		{
			title: "Práve schvaluje",
			width: 300,
			formItemProps: {
				label: "Práve schvaluje",
			},
			dataIndex: "approvalProcessWaitingOn",
			key: "approvalProcessWaitingOn",
			render: (value: any) => {
				if (!value.name) {
					return (
						<Box textColor={"gray.800"} opacity={0.2}>
							Schvalovanie ukončené
						</Box>
					);
				}

				return <Box>{value.name}</Box>;
			},
			renderFormItem: (_, { defaultRender }) => {
				return <SingleUserSelect noStyle placeholder={""} />;
			},
		},
		{
			title: "Partner",
			width: 400,
			key: "partner",
			valueType: "select",
			sorter: false,
			render: (_, record) => {
				return record?.partner?.name;
			},
			renderFormItem: (e, { defaultRender }) => {
				return <CompanyPartnerSelect noStyle />;
			},
		},
		{
			title: t("attributes.dateReceived.label"),
			dataIndex: "dateReceived",
			width: 200,
			key: "dateReceived",
			sorter: true,
			valueType: "date",
			renderFormItem: (_, { defaultRender }) => {
				return (
					<ProFormDateRangePicker
						fieldProps={{
							ranges: andDatePickerPresetRanges,
							placeholder: ["Od", "Do"],
						}}
						noStyle
					/>
				);
			},
		},
		{
			title: t("attributes.subject.label"),
			dataIndex: "subject",
			valueType: "text",
			ellipsis: true,
			width: 200,
			key: "subject",
			sorter: true,
		},
		{
			title: t("attributes.dueDate.label"),
			dataIndex: "dueDate",
			key: "dueDate",
			sorter: true,
			width: 200,
			valueType: "date",
			renderFormItem: (_, { defaultRender }) => {
				return (
					<ProFormDateRangePicker
						fieldProps={{
							placeholder: ["Od", "Do"],
							ranges: andDatePickerPresetRanges,
						}}
						noStyle
					/>
				);
			},
		},
		{
			title: t("attributes.project.label"),
			dataIndex: "project",
			render: (value: any, entity) => entity?.project?.name,
			sorter: false,
			width: 400,
			key: "project",
			renderFormItem: (_, { defaultRender }) => {
				return (
					<SingleProjectSelect
						noStyle
						placeholder={t("attributes.project.label")}
						name={"project"}
					/>
				);
			},
		},
		{
			title: t("attributes.department.label"),
			dataIndex: "department",
			key: "department",
			sorter: false,
			width: 200,
			render: (value: any) => (value ? value.name : ""),
			renderFormItem: (_, { defaultRender }) => {
				return (
					<SingleDepartmentPicker
						noStyle
						placeholder={t("attributes.department.label")}
					/>
				);
			},
		},
		{
			title: t("attributes.sum.label"),
			dataIndex: "sum",
			key: "sum",
			width: 200,
			sorter: true,
			defaultSortOrder: "descend",
			render: (value: any, entity) => (
				<div>
					{new Intl.NumberFormat("sk-SK", {
						style: "currency",
						currency: "EUR",
					}).format(parseFloat(value))}
				</div>
			),
		},
		{
			title: t("attributes.type.label"),
			dataIndex: "type",
			key: "type",
			valueType: "select",
			width: 100,
			valueEnum: {
				["regular"]: "Normálna",
				["advance"]: "Zálohová",
			},
			sorter: false,
		},
		{
			title: "Schvalovanie",
			key: "quick-approval",
			fixed: "right",
			width: 100,
			hideInDescriptions: true,
			render: (value, entity) => {
				return (
					<>
						{entity.approvalProcessCurrentStepType ===
							Enum_Invoicereadmodel_Approvalprocesscurrentsteptype.ApprovalProcessControlStep ? (
							<QuickApprovalActions
								invoiceId={entity.id}
								approvalProcessCurrentStepId={
									entity.approvalProcessCurrentStepId
								}
								approvalWaitingForUserWithId={
									entity.approvalProcessWaitingOn?.id
								}
							/>
						) : (
							<Box fontSize={"xs"} textColor={"gray.800"} opacity={0.2}>
								Ukončené
							</Box>
						)}
					</>
				);
			},
		},
		{
			title: t("attributes.attachments.label"),
			dataIndex: "attachments",
			renderFormItem: () => null,
			render: (value: any = []) => {
				if (!value || value === "-") return;

				return value ? (
					<div
						className={clsx({
							"flex space-x-1 text-xl text-primary justify-center": true,
							"opacity-70": true,
						})}
					>
						{value?.map((attachment, index) => (
							<Tooltip key={attachment.id + index} title={attachment.name}>
								<AttachmentItem attachment={attachment} />
							</Tooltip>
						))}
					</div>
				) : (
					""
				);
			},
			width: 100,
			key: "attachments",
		},
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			valueType: "text",
			defaultSortOrder: "descend",
			sorter: true,
			width: 60,
		},
	];
	return useMemo(() => columns, []);
};
