import {
  AudioOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import React from "react";

export const getAttachmentTypeIcon = (type: string) => {
  if (type.indexOf("image") !== -1) {
    return <FileImageOutlined />;
  } else if (type.indexOf("audio") !== -1) {
    return <AudioOutlined />;
  } else if (type.indexOf("video") !== -1) {
    return <VideoCameraOutlined />;
  } else if (
    [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(type)
  ) {
    return <FileExcelOutlined />;
  } else if (
    [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(type)
  ) {
    return <FileWordOutlined />;
  } else if (["application/pdf"].includes(type)) {
    return <FilePdfOutlined />;
  } else if (
    [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ].includes(type)
  ) {
    return <FilePptOutlined />;
  }
};
