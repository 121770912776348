import { queryClient } from "@/lib/react-query/client";
import { useGetApprovalProcessesQuery } from "@/lib/sdk/sdk";
import { convertIdToNumber } from "@/lib/utils/convert-it";
import { prefetchSingleCompanyPartnerQuery } from "../../company-partners/hooks/useSingleCompanyPartnerQuery.hook";
import { prefetchSingleDepartmentQuery } from "../../departments/hooks/useSingleDepartmentQuery.hook";
import { prefetchGetAttachmentQuery } from "../../s3-files/hooks/useGetAttachment";
import { getSingleInvoice } from "../api/invoice.api";
import { USE_SINGLE_INVOICE_QUERY_KEY } from "../consts/invoice.query-keys";
import { prefetchSingleInvoiceQuery } from "../hooks/useSingleInvoiceQuery.hook";

export const prefetchInvoice = (invoice) => {
  prefetchSingleInvoiceQuery(invoice?.source_entity?.id);

  prefetchSingleCompanyPartnerQuery(convertIdToNumber(invoice.partner.id));

  prefetchSingleDepartmentQuery(convertIdToNumber(invoice.partner.id));

  invoice.attachments.forEach((attachment) =>
    prefetchGetAttachmentQuery(attachment.id)
  );

  queryClient.prefetchQuery(
    useGetApprovalProcessesQuery.getKey({
      input: {
        targetModelName: "invoice",
        targetModelId: `${invoice.source_entity?.id}`,
      },
    }),
    useGetApprovalProcessesQuery.fetcher({
      input: {
        targetModelName: "invoice",
        targetModelId: `${invoice.source_entity?.id}`,
      },
    }),
    {
      staleTime: 8000,
      cacheTime: 8000,
    }
  );
};

/**
 * Here we need to fetch the invoice first since we dont know anything else
 * about it and there fore cant prefetch rest of the fields
 * @param invoiceId
 */
export const prefetchInvoiceById = async (invoiceId) => {
  const resp = await queryClient.fetchQuery(
    [USE_SINGLE_INVOICE_QUERY_KEY, { id: convertIdToNumber(invoiceId) }],
    () => getSingleInvoice(convertIdToNumber(invoiceId)),
    {
      staleTime: 8000,
    }
  );

  prefetchInvoice(resp);
};
