import { gql } from "graphql-tag";
import { invoiceFragment } from "./invoice.fragment";

// Query to retrieve single invoice
export const GET_SINGLE_INVOICE_QUERY = gql`
  ${invoiceFragment}
  query getSingleInvoiceQuery($id: ID!) {
    invoice(id: $id) {
      ...invoiceFragment
    }
  }
`;

// Query to retrieve multiple invoices
export const GET_INVOICES_QUERY = gql`
  ${invoiceFragment}
  query getInvoicesQuery(
    $sort: String = "id:desc"
    $limit: Int
    $start: Int
    $where: JSON
  ) {
    invoiceReadModels(
      sort: $sort
      limit: $limit
      start: $start
      where: $where
    ) {
      ...invoiceFragment
    }

    invoicesCount(where: $where)
  }
`;
