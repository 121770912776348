import { CustomColumnConfig } from "@/lib/shared/model/column-state";
import { ProFormDigit } from "@ant-design/pro-form";
import { Table, Typography } from "antd";
import React, { memo } from "react";

export interface TableSummaryProps<T> {
  className?: string;
  tableData: readonly T[];
  sumColumns: string[];
  columnConfig: CustomColumnConfig;
}

export const TableSummary = memo(function TableSummary<T>(
  props: TableSummaryProps<T>
) {
  const { tableData = [], sumColumns = [], columnConfig = {} } = props;
  const columnsToCalculateSumsFor = sumColumns;
  const sums = {};

  tableData.forEach((item) => {
    for (const columnKey of columnsToCalculateSumsFor) {
      // Ensure the column sum exists
      if (sums[columnKey] == undefined) sums[columnKey] = 0;

      sums[columnKey] += item[columnKey];
    }
  });

  if (sumColumns.length === 0) {
    return <div />;
  }

  return (
    <Table.Summary>
      <Table.Summary.Row className="bg-gray-50">
        {Object.values(columnConfig)
          .sort((a, b) => a.order - b.order)
          .map((config, index) => (
            <React.Fragment key={index}>
              {config.show !== false && (
                <Table.Summary.Cell index={index}>
                  {columnsToCalculateSumsFor.includes(config.key) && (
                    <Typography.Text>
                      <ProFormDigit
                        initialValue={sums[config.key]}
                        readonly
                        noStyle
                        fieldProps={{
                          value: sums[config.key],
                          parser: (value) => value.replace(" €", ""),
                          formatter: (value) => `${value} €`,
                          step: 0.01,
                          precision: 2,
                        }}
                      />
                    </Typography.Text>
                  )}
                </Table.Summary.Cell>
              )}
            </React.Fragment>
          ))}
      </Table.Summary.Row>
    </Table.Summary>
  );
});
