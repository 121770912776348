import { queryClient } from "@/lib/react-query/client";
import {
  ApprovalStepDecisionOption,
  useApproveOrRejectApprovalStepMutation,
  useGetApprovalProcessesQuery,
} from "@/lib/sdk/sdk";
import { userSelector } from "@/store/slices/authSlice";
import { Box, BoxProps } from "@chakra-ui/react";
import { Space } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { USE_SINGLE_INVOICE_QUERY_KEY } from "../../../consts/invoice.query-keys";
import { ApproveDocumentModal } from "../../ApprovalProcess/components/ApprovalStepCard/components/modals/ApproveDocumentModal";
import { RejectDocumentModal } from "../../ApprovalProcess/components/ApprovalStepCard/components/modals/RejectDocumentModal";

export interface QuickApprovalActionsProps extends BoxProps {
  approvalWaitingForUserWithId: string;
  approvalProcessCurrentStepId: number;
  invoiceId: string;
}

export const QuickApprovalActions = React.memo(function QuickApprovalActions(
  props: QuickApprovalActionsProps
) {
  const {
    className = "",
    invoiceId,
    approvalWaitingForUserWithId,
    approvalProcessCurrentStepId,
  } = props;

  const currentUser = useSelector(userSelector);

  const isCurrentUserApproving = useMemo(
    () => currentUser.id === approvalWaitingForUserWithId,
    [currentUser, approvalWaitingForUserWithId]
  );

  const { mutateAsync, isLoading } = useApproveOrRejectApprovalStepMutation({
    onSuccess: (data) => {
      queryClient.setQueryData(
        useGetApprovalProcessesQuery.getKey({
          input: {
            targetModelId: `${invoiceId}`,
            targetModelName: "invoice",
          },
        }),
        {
          approvalProcesses: [
            data.approveOrRejectApprovalStep.approval_process,
          ],
        }
      );

      setTimeout(() => {
        queryClient.invalidateQueries(USE_SINGLE_INVOICE_QUERY_KEY);
        queryClient.invalidateQueries("getInvoiceReadModels");
      }, 300);
    },
  });

  //if (!isCurrentUserApproving) {
  //  return <Box fontSize={"xx-small"}>Vy neschvalujete.</Box>;
  //}

  return (
    <Box {...props}>
      <Space className="actions" align="center">
        <ApproveDocumentModal
          buttonProps={{
            children: "",
            disabled: !isCurrentUserApproving,
          }}
          onFinish={async (values) => {
            await mutateAsync({
              input: {
                id: parseInt(`${approvalProcessCurrentStepId}`),
                decision: ApprovalStepDecisionOption.Approve,
                ...values,
              },
            });

            return true;
          }}
        />
        <RejectDocumentModal
          buttonProps={{
            children: "",
            disabled: !isCurrentUserApproving,
          }}
          onFinish={async (values) => {
            await mutateAsync({
              input: {
                id: parseInt(`${approvalProcessCurrentStepId}`),
                decision: ApprovalStepDecisionOption.Reject,
                ...values,
              },
            });

            return true;
          }}
        />
      </Space>
    </Box>
  );
});
